import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { graphql, PageProps } from 'gatsby';
import SEO from '@components/SEO';
import { theme } from '@styles';
import FooterSection from '@pageSections/common/footer';
import { IPrismicLegal, PageContext } from '@appTypes';

import CallToActionSection from '@pageSections/common/callToAction';
import { isBrowser } from '@utils';

const { colors, breakpoints } = theme;

const Legal: React.FC<PageProps<IPrismicLegal, PageContext>> = ({
  data: { prismicDataClub, prismicFooterData, prismicDataLegal, prismicDataTeams, prismicDataApi, prismicCtaData },
  pageContext,
  location,
}) => {
  const [selectedTab, setSelectedTab] = useState<string>('teams');
  const [mobileAppSelected, setMobileAppSelected] = useState('');
  const legalPageClubData = prismicDataClub.edges[0].node.data;
  const legalPageTeamsData = prismicDataTeams.edges[0].node.data;
  const legalPageApiData = prismicDataApi.edges[0].node.data;

  const { meta_title, meta_description, meta_image, meta_url } = prismicDataLegal.edges[0].node.data;

  useEffect(() => {
    if (isBrowser()) {
      const urlParams = new URLSearchParams(window.location.search);
      const mobileApp = urlParams.get('mobileApp');
      const tab = urlParams.get('tab');
      if (mobileApp) {
        setMobileAppSelected(mobileApp);
        setSelectedTab('');
      }
      if (tab) {
        setSelectedTab(tab);
      }
    }
  }, []);

  const handleToggleButton = (tab: 'club' | 'teams' | 'api') => {
    setSelectedTab(tab);
  };

  const { locale } = pageContext;

  return (
    <>
      <Container style={mobileAppSelected ? { marginTop: 0 } : { marginTop: 34 }}>
        <SEO
          title={meta_title}
          description={meta_description}
          image={meta_image.url}
          url={meta_url}
          pathname={location.pathname}
          name='Hyperhuman'
          locale={locale}
        />
        {!mobileAppSelected && (
          <>
            <TitleContainer>Terms and conditions</TitleContainer>
            <ToggleButtons>
              <ToggleButton
                onClick={() => {
                  handleToggleButton('teams');
                }}
                active={selectedTab === 'teams'}
                style={{
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }}
              >
                Teams
              </ToggleButton>
              <ToggleButton
                onClick={() => {
                  handleToggleButton('api');
                }}
                active={selectedTab === 'api'}
                style={{
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  borderTopLeftRadius: 0,
                  borderLeft: 0,
                  borderBottomLeftRadius: 0,
                }}
              >
                Api
              </ToggleButton>
              <ToggleButton
                onClick={() => {
                  handleToggleButton('club');
                }}
                active={selectedTab === 'club'}
                style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeft: 0 }}
              >
                Club
              </ToggleButton>
            </ToggleButtons>
          </>
        )}
        {selectedTab === 'club' || mobileAppSelected === 'club' ? (
          <PrismicLegalContent dangerouslySetInnerHTML={{ __html: legalPageClubData.content.html }} />
        ) : selectedTab === 'teams' ? (
          <PrismicLegalContent
            dangerouslySetInnerHTML={{
              __html: legalPageTeamsData.content.html,
            }}
          />
        ) : (
          <PrismicLegalContent
            dangerouslySetInnerHTML={{
              __html: legalPageApiData.content.html,
            }}
          />
        )}
      </Container>
      {!mobileAppSelected && <CallToActionSection prismicCtaData={prismicCtaData} />}
      {!mobileAppSelected && <FooterSection prismicFooterData={prismicFooterData} />}
    </>
  );
};

export default Legal;

const ToggleButtons = styled.div`
  display: flex;
  height: 50px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
`;
const ToggleButton = styled.div<{ active: boolean }>`
  cursor: pointer;
  width: 110px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 2px solid ${colors.mystic};
  text-transform: uppercase;
  font-size: 14px;
  line-height: 18px;
  font-weight: ${(props) => (props.active ? 700 : 400)};
  color: ${(props) => (props.active ? colors.grannySmith : colors.outerSpace)};
  background-color: ${(props) => (props.active ? colors.mystic : 'transparent')};
`;

const TitleContainer = styled.h1`
  font-family: 'Poppins';
  color: ${colors.outerSpace};
  font-size: 40px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.2px;
  margin-top: 100px;
  text-align: center;
  margin-bottom: 30px;
`;

const PrismicLegalContent = styled.div`
  font-size: 14px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    max-width: 700px;
    margin: 0 auto;
  }
`;

const Container = styled.div`
  margin-top: 34px;
  margin-bottom: 26px;
  padding: 0 16px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin-top: 44px;
    margin-bottom: 54px;
    padding: 0 24px;
  }
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 54px;
    margin-bottom: 110px;
  }
`;

export const pageQuery = graphql`
  query LegalQuery($locale: String!) {
    prismicDataApi: allPrismicTermsAndConditionsApi(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          lang
          data {
            content {
              html
            }
          }
        }
      }
    }
    prismicDataLegal: allPrismicTermsAndConditionsClub(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          lang
          data {
            meta_title
            meta_image {
              url
            }
            meta_description
            meta_url
            meta_keywords
          }
        }
      }
    }
    prismicDataClub: allPrismicTermsAndConditionsClub(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          lang
          data {
            content {
              html
            }
          }
        }
      }
    }
    prismicDataTeams: allPrismicTermsAndConditionsTeams(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          lang
          data {
            content {
              html
            }
          }
        }
      }
    }
    prismicFooterData: allPrismicIndex(filter: { lang: { eq: $locale } }) {
      ...FooterFieldsFragment
    }
    prismicCtaData: allPrismicIndex(filter: { lang: { eq: $locale } }) {
      ...CtaFieldsFragment
    }
  }
`;
